import { changeConsentLanguage } from '../consent';
import type { LanguageCode } from '../types';
import { getLocalStorageItem, setLocalStorageItem, StorageKey } from '../utils/storage';

export function getUserDefinedLanguage(): LanguageCode | null {
  return getLocalStorageItem(StorageKey.BROWSER_LANGUAGE);
}

export function setUserDefinedLanguage(language: LanguageCode): void {
  setLocalStorageItem(StorageKey.BROWSER_LANGUAGE, language);

  const languageChangeFromSettingsEvent = new CustomEvent('languageChangeFromSettings', {
    detail: language,
  });

  window.dispatchEvent(languageChangeFromSettingsEvent);

  changeConsentLanguage(language);
}

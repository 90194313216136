import { getDefaultLoader } from 'piral-base';
import { type BaseComponentProps, createInstance, Piral } from 'piral-core';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import { logout } from './auth';
import details from './details.codegen';
import * as errorActions from './error-handling/error-actions';
import { dispatchInactivityDialogClosed } from './inactivity/user-interaction';
import { setUserDefinedLanguage } from './localization/user-defined-language';
import { updateDependencies } from './ng';
import { createPlugins } from './plugins';
import GlobalStyle from './style';
import type { HdpApp, LayoutConfig } from './types';
import { createFeedUrls, fetchPilets, getFeeds } from './utils/fetch-pilets';

export function renderApp(app: HdpApp, layout: LayoutConfig): void {
  const load = getDefaultLoader();

  const TranslationWrapper = (props: BaseComponentProps) => {
    const i18n = props.piral.getLanguageProvider();
    return <I18nextProvider {...props} i18n={i18n} />;
  };

  const instance = createInstance({
    actions: {
      ...errorActions,
    },
    state: {
      components: layout.components,
      bellNotifications: [],
      errorMessages: [],
      errorComponents: layout.errorComponents,
      registry: {
        applicationInfos: {},
        pages: layout.pages,
        extensions: layout.extensions,
        wrappers: {
          modal: TranslationWrapper,
          menu: TranslationWrapper,
          extension: TranslationWrapper,
          page: TranslationWrapper,
        },
      },
    },
    plugins: createPlugins(app, layout),
    loadPilet(entry) {
      if (entry.spec === 'v2') {
        updateDependencies(entry.dependencies);
      }

      return load(entry);
    },
    requestPilets() {
      const feeds = getFeeds(app.config.feeds, layout.type);
      return fetchPilets(createFeedUrls(app.config.environment, app.config.endpoints.feed, feeds));
    },
  });

  instance.once('logout', () => logout(app.config));
  instance.on('inactivityDialogClosed', () => dispatchInactivityDialogClosed());
  instance.on('languageChange', (language) => setUserDefinedLanguage(language));

  instance.root.addVersionInfo(app.title, details.appVersion);

  const container = document.querySelector('#app');

  if (!container) {
    return;
  }

  const root = createRoot(container);

  root.render(
    <I18nextProvider i18n={app.languageProvider}>
      <GlobalStyle />
      <Piral instance={instance} />
    </I18nextProvider>
  );
}

import type { HdpApp, LanguageCode } from '../types';
import { StorageKey } from '../utils/storage';

export const createLanguageChangeObservers = (
  app: HdpApp,
  changeLanguageTo: (language: LanguageCode) => void
): void => {
  app.currentLanguage$.subscribe(async (language: LanguageCode) => {
    document.documentElement.setAttribute('lang', language);

    await app.languageProvider.loadLanguages(language);
    await app.languageProvider.changeLanguage(language);
  });

  window.addEventListener('languagechange', () => changeLanguageTo(app.getUserLanguage()));

  window.addEventListener('languageChangeFromSettings', (event: Event) => {
    const language = (event as CustomEvent).detail;
    changeLanguageTo(language);
  });

  const handleLanguageUpdate = (event: StorageEvent) => {
    if (event.key === StorageKey.BROWSER_LANGUAGE && event.newValue !== event.oldValue) {
      changeLanguageTo(app.getUserLanguage());
    }
  };

  window.addEventListener('storage', handleLanguageUpdate);
};

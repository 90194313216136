import { match } from '@formatjs/intl-localematcher';

import type { LanguageLoader } from '../plugins/localization/plugin';
import type { LanguageCode, LocaleCode } from '../types';

export const transformLanguageToLocale =
  (
    originalLoader: LanguageLoader,
    languageToLocaleMapper: (language: LanguageCode) => LocaleCode
  ) =>
  (language: LanguageCode): Promise<Record<string, string>> => {
    const localeCode = languageToLocaleMapper(language);
    return originalLoader(localeCode);
  };

export const mapLanguageToLocale =
  (availableLocales: Array<LocaleCode>, defaultLocale: LocaleCode) =>
  (language: LanguageCode): LocaleCode =>
    match([language], availableLocales, defaultLocale);

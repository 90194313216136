import type { LanguageCode, LegalLanguages } from '../types';

export function getLegalLanguageFromConfig(
  country: string,
  legalLanguages: LegalLanguages,
  userLanguage: LanguageCode
): LanguageCode {
  const legalLanguage = legalLanguages[country.toLowerCase()] || legalLanguages['default'];

  if (typeof legalLanguage === 'object') {
    return legalLanguage[userLanguage] || legalLanguage['default'] || '';
  }

  return legalLanguage || '';
}

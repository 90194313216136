import type { i18n } from 'i18next';
import type { PiralPlugin } from 'piral-core';
import { distinctUntilChanged, map, type Observable } from 'rxjs';

import { setupI18next } from '../../localization/i18n';
import { mapLanguageToLocale, transformLanguageToLocale } from '../../localization/utils';
import type { HdpApp, LanguageCode, LocaleCode } from '../../types';

/* eslint-disable @typescript-eslint/no-empty-interface */
declare module 'piral-core/lib/types/custom' {
  interface PiletCustomApi extends PiletLocalizationApi {}
}

export interface LanguageLoader {
  /**
   * I18n callback to load the translation on locale change.
   * @param language locale to load the translation file
   */
  (language: LanguageCode | LocaleCode): Promise<Record<string, string>>;
}

export interface PiletLocalizationApi {
  /**
   * Provides an event stream which emits every time the browser locale changes.
   * @deprecated Migrate to `userLanguage$` instead.
   */
  browserLocale$: Observable<LocaleCode>;
  /**
   * Provides an event stream which emits every time the user changes their language selection in the format of mixed locale codes.
   * @deprecated Migrate to `userLanguage$` instead
   */
  userLocale$: Observable<LocaleCode>;
  /**
   * Provides an event stream which emits every time the user changes their language selection in the format of IETF BCP 47 tag standard.
   */
  userLanguage$: Observable<LanguageCode>;
  /**
   * Provides an array of all supported locales as mixed locale code of the HDP App Shell.
   * @deprecated Migrate to `getAvailableLanguages` instead
   */
  getAvailableLocales(): ReadonlyArray<LocaleCode>;
  /**
   * Provides an array of all supported language codes as IETF BCP 47 tag standard of the HDP App Shell.
   */
  getAvailableLanguages(): ReadonlyArray<LanguageCode>;
  /**
   * Returns the locale of the browser.
   * @return locale of the browser
   * @deprecated Migrate to `getUserLanguage()` instead.
   */
  getBrowserLocale(): LocaleCode;
  /**
   * Returns the locale of the user if configured, else the browser locale is returned.
   * @return locale in the format of mixed locale codes according to the user's preferences
   * @deprecated Migrate to `getUserLanguage()` instead.
   */
  getUserLocale(): LocaleCode;
  /**
   * Returns the language of the user if configured, else the browser language is returned if HDP App Shell supports it,
   * otherwise default language returned.
   * @return language in the format of IETF BCP 47 tag standard according to the user's preferences
   */
  getUserLanguage(): LanguageCode;
  /**
   * Returns the default locale in the format of mixed locale code which can be used as a fallback.
   * @return default locale.
   * @deprecated Migrate to `getDefaultLanguage()` instead.
   */
  getDefaultLocale(): LocaleCode;
  /**
   * Returns default language in the format of IETF BCP 47 tag standard which can be used as a fallback.
   * @return default language.
   */
  getDefaultLanguage(): LanguageCode;
  /**
   * API to integrate the `i18next` hook.
   * @param loader Callback which is invoked on language switch
   * @param [format=LOCALE_CODE] Specify the format of the used language code
   */
  setLanguageProvider(loader: LanguageLoader, format?: LanguageCodeFormat): void;
  /**
   * Returns the i18n instance for further usage in root module or Angular's injection context.
   * @return i18n instance
   */
  getLanguageProvider(): i18n;
  /**
   * Returns the language of the user which is configured in ZEISS ID.
   * If the country parameter is provided the legal language for this country is returned.
   * @param {string} country - Provide this parameter to receive legal language for a specific country.
   * @param {LanguageCodeFormat} [format=LOCALE_CODE] Specify the format of the used language code
   * @return the legal locale for the provided context
   */
  getLegalLanguage(country?: string, format?: LanguageCodeFormat): LanguageCode | LocaleCode;
}

/**
 * LOCALE_CODE - Represents the legacy mixed format of locales like `en`, `pt-BR`, etc.
 * BCP_47_LANGUAGE_CODE - Represents the IETF BCP 47 tag standard like 'en-US', 'pt-BR', etc.
 */
type LanguageCodeFormat = 'LOCALE_CODE' | 'BCP_47_LANGUAGE_CODE';

/**
 * Creates an array for storing the language providers above all pilets and returns the PiletLocalizationApi.
 * @param app HdpApp instance
 */
export function createLocalizationApi(app: HdpApp): PiralPlugin<PiletLocalizationApi> {
  const currentLanguage$ = app.currentLanguage$.pipe(distinctUntilChanged());
  const providers: Array<i18n> = [];

  currentLanguage$.subscribe(async (language) => {
    await Promise.all(
      providers.map(async (provider) => {
        await provider.loadLanguages(language);
        await provider.changeLanguage(language);
      })
    );
  });

  return () => () => localizationApi(app, currentLanguage$, providers);
}

function localizationApi(
  app: HdpApp,
  userLanguage$: Observable<LanguageCode>,
  providers: Array<i18n>
): PiletLocalizationApi {
  const languageToLocale = mapLanguageToLocale(app.config.availableLocales, app.defaultLocale);

  const browserLocale$ = app.currentLanguage$.pipe(map(languageToLocale));

  /**
   * All pilets have their own `languageProvider`, which is created locally for them,
   * but registered into the App Shell's providers too by `setLanguageProvider`.
   */
  let languageProvider = app.languageProvider.cloneInstance();

  return {
    browserLocale$,
    userLanguage$,
    userLocale$: browserLocale$,
    getBrowserLocale: app.getBrowserLocale,
    getUserLanguage: app.getUserLanguage,
    getUserLocale: app.getUserLocale,
    getAvailableLocales() {
      return [...app.config.availableLocales];
    },
    getAvailableLanguages() {
      return [...app.config.availableLanguages];
    },
    getDefaultLocale() {
      return app.defaultLocale;
    },
    getDefaultLanguage() {
      return app.defaultLanguage;
    },
    setLanguageProvider(loader: LanguageLoader, format: LanguageCodeFormat = 'LOCALE_CODE') {
      if (format === 'LOCALE_CODE') {
        languageProvider = setupI18next(
          transformLanguageToLocale(loader, languageToLocale),
          app.defaultLocale
        );
      } else {
        languageProvider = setupI18next(loader, app.defaultLanguage);
      }

      languageProvider.changeLanguage(app.getUserLanguage());

      providers.unshift(languageProvider);
    },
    getLanguageProvider() {
      return languageProvider;
    },
    getLegalLanguage(country?: string, format: LanguageCodeFormat = 'LOCALE_CODE') {
      const legalLanguage = app.getLegalLanguage(country ?? app.country);

      if (format === 'LOCALE_CODE') {
        // Find available locale based on legal language (e.g. 'en' -> 'en-US', but 'pt-BR' -> 'pt-BR')
        return languageToLocale(legalLanguage);
      }

      return legalLanguage;
    },
  };
}

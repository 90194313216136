import type { LanguageCode } from '@arnold/app-shell';
import i18next, {
  type CallbackError,
  type i18n,
  type ReadCallback,
  type ResourceKey,
} from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

export function setupI18next(
  loader: (language: LanguageCode) => Promise<Record<string, string>>,
  fallbackLng: LanguageCode
): i18n {
  const instance = i18next.createInstance({
    fallbackLng,
    /*
     * This will prevent the instance to try to load multiple languages in case of IETF BCP 47 Language code.
     * Example: Default behavior is to try to load 'en-US' and 'en' in case of 'en-US'
     */
    load: 'currentOnly',
  });

  instance
    .use(
      resourcesToBackend((language: LanguageCode, _namespace: string, callback: ReadCallback) => {
        loader(language).then(
          (res: ResourceKey) => callback(null, res),
          (err: CallbackError) => callback(err, null)
        );
      })
    )
    .init({
      interpolation: {
        escapeValue: false,
      },
    });

  return instance;
}
